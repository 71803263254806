import { Application } from '@splinetool/runtime';

const canvas = document.getElementById('canvas3d') as HTMLCanvasElement;
const app = new Application(canvas);
app.load('https://prod.spline.design/URN4k-jWhIGO3C7J/scene.splinecode');


// Select the header element
const header = document.getElementById('navbar');
const year = document.getElementById('year');
const emailContainers = document.getElementsByClassName('email-obfuscate');

// Set the current year
year.textContent = new Date().getFullYear().toString();

// Function to handle scroll event
function onScroll() {
  if (window.scrollY > 50) {
    header.classList.add('shadow-lg');
    header.classList.remove('bg-transparent');
  } else {
    header.classList.add('bg-transparent');
    header.classList.remove('shadow-lg');
  }
}

// Attach the scroll event listener to the window
window.addEventListener('scroll', onScroll);
window.addEventListener('load', onScroll);


// Obfuscate email addresses
for (let i = 0; i < emailContainers.length; i++) {
  const e = emailContainers[i];
  const base64email = e.getAttribute('data-email');
  const email = atob(base64email);
  const replace = e.getAttribute('data-replace') || false;
  // check if element is an anchor tag
  if (e.tagName === 'A') {
    e.setAttribute('href', `mailto:${email}`);
  } 

  if (replace) {
    e.textContent = email;
  }
}